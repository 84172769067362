@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
@font-face {
  font-family: "Eat well Chubby";
  src: url(/static/media/EatwellChubby.4c22a83f.woff) format("woff");
}

body {
  overflow: hidden;
  margin: 0;
  font-family: "Eat well Chubby", "Concert One", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.swalModal {
  z-index: 9999999999 !important;
}

